<!-- =========================================================================================
  File Name: ItemGridView.vue
  Description: Item Component - Grid VIew
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- <div class="item-grid-view vx-row match-height">
        <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <vx-card class="grid-view-item mb-base overflow-hidden full-h-item">
    <template slot="no-body">
      <div class="grid-item">
        <!-- ITEM IMAGE -->
        <div
          class="item-img-container bg-white h-64 flex items-center justify-center mb-4"
        >
          <img
            :src="item.ImageUrl"
            @error="replaceByDefault"
            class="grid-view-img px-4"
          />
        </div>
        <div class="item-details px-4">
          <!-- RATING & PRICE -->
          <!--                        <div class="flex justify-between items-center">-->
          <!--                            <div class="text-warning border border-solid border-warning flex py-1 px-2 rounded">-->
          <!--                                <span class="text-sm mr-1">5</span>-->
          <!--                                <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />-->
          <!--                            </div>-->
          <!--                             <h6 class="font-bold">$999</h6>-->
          <!--                        </div>-->

          <!-- TITLE & DESCRIPTION -->
          <div class="my-4">
            <h6 class="trunca font-semibold mb-1">
              {{ item.Name.find((n) => (n.Language = 'EST')).Value }}
            </h6>
            <p class="item-description truncate text-sm">
              Item Code: {{ item.Code }}
            </p>
          </div>
        </div>

        <!-- SLOT: ACTION BUTTONS -->
        <slot name="action-buttons" />
      </div>
    </template>
  </vx-card>
  <!--  </div>
    </div> -->
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placeholder_image: 'https://i.stack.imgur.com/y9DpT.jpg',
    };
  },
  methods: {
    onClickProductDetails(id) {
      this.$router.push({ path: `/products/${id}` });
    },
    replaceByDefault(e) {
      e.target.src = this.placeholder_image;
    },
  },
};
</script>

<style lang="scss" scoped>
.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  height: 100%;
  background-color: black;
}
.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.grid-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.vx-card__collapsible-content {
  height: 100%;
}
</style>
